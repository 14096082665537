<template>
    <ASelect
        ref="selectRef"
        mode="multiple"
        v-model:value="valueState"
        placeholder="Pilih Supplier"
        allow-clear
        show-search
        :not-found-content="state.loading ? undefined : 'Tidak ditemukan'"
        option-filter-prop="label"
        option-label-prop="label"
        @search="onSearch"
        @blur="() => (state.params.q = null)"
        @select="() => (state.params.q = null)"
        :open="state.dropdownOpen"
        @dropdownVisibleChange="handleDropdownVisibleChange"
        :loading="state.loading"
        :show-arrow="true"
        style="width:300px">
        <ASelectOption v-for="d in state.data" :key="d.id" :label="d.name">
            <span v-html="highlight(d.name)"></span>
        </ASelectOption>

        <template v-if="state.loading" #notFoundContent>
            <ASpin size="small" />
        </template>

        <template v-if="is_create" #dropdownRender="{ menuNode: menu }">
            <v-nodes :vnodes="menu" />
            <ADivider style="margin: 4px 0" />
            <div class="m-2" ref="dropdownContent">
                <AInput
                    v-model:value="state.form.item"
                    placeholder="Masukkan data baru"
                    @keydown.enter.prevent="addItem" />
                <AButton
                    type="primary"
                    block
                    class="mt-2"
                    :loading="state.isSubmitting"
                    @mousedown.prevent="addItem">
                    <PlusOutlined /> Tambahkan
                </AButton>
            </div>
        </template>
    </ASelect>
</template>

<script>
import { onMounted, reactive, watch, ref } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, keyBy, merge, pickBy, values } from 'lodash'
import { PlusOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    PlusOutlined,
    VNodes: (_, { attrs }) => attrs.vnodes,
  },
    props: {
        value: {
            type: [Array, Number, Object, String],
            default: null,
        },
        is_competitor: {
            type: [Boolean],
            default: false,
        },
        is_create: {
            type: [Boolean],
            default: () => false,
        },
        joinOptions: {
            type: [Array],
            default: () => [],
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const selectRef = ref(null)
        const dropdownContentRef = ref(null)
        const state = reactive({
            endpoint: `/api/filter/supplier`,
            loading: false,
            isSubmitting: false,
            data: [],
            params: {
                q: '',
                active: 1,
                is_competitor: props.is_competitor,
            },
            form: {
                item: '',
            },
            dropdownOpen: false,
        })

        const fetchData = () => {
            state.loading = true
            apiClient.get(state.endpoint, { params: pickBy(state.params) })
                .then(({ data }) => {
                    const options = props.joinOptions.filter(item => item.id !== undefined)
                    if (options.length > 0) {
                        data = values(merge(keyBy(options, 'id'), keyBy(data, 'id')))
                    }
                
                    state.data = data
                })
                .finally(() => {
                    state.loading = false
                })
        }

        const onSearch = value => {
            state.params.q = value
        }

        const highlight = value => {
            return value.replace(new RegExp(state.params.q, 'gi'), match => {
                return `<span style="background-color: yellow;">${match}</span>`
            })
        }

        const addItem = () => {
            const trimmedItem = state.form.item.trim()
            if (trimmedItem) {
                state.isSubmitting = true
                
                apiClient.post(state.endpoint, { name: trimmedItem })
                    .then(({ data }) => {
                        state.data.push(data)
                        state.form.item = ''
                        state.dropdownOpen = true // Tetap buka dropdown
                    })
                    .finally(() => {
                        state.isSubmitting = false
                    })
            }
        }

        const handleDropdownVisibleChange = (visible) => {
            const selectElement = selectRef.value?.$el
            const dropdownContentElement = dropdownContentRef.value

            if (
                visible || // Jika dropdown dibuka secara eksplisit
                (selectElement?.contains(event.target) || dropdownContentElement?.contains(event.target))
            ) {
                // Jangan tutup dropdown jika klik terjadi di dalam select atau dropdown content
                state.dropdownOpen = true
            } else {
                // Tutup dropdown jika klik terjadi di luar elemen
                state.dropdownOpen = false
            }
        }

        onMounted(() => {
            fetchData()
        })

        watch(state.params, debounce(() => {
            fetchData()
        }, 300))

        return {
            valueState: useVModel(props, 'value'),
            selectRef,
            dropdownContentRef,
            state,
            highlight,
            onSearch,
            addItem,
            handleDropdownVisibleChange,
        }
    },
}
</script>